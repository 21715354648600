/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Elements';
import { ReviewCardFull } from './ReviewCardFull';
import { scrapedReviews } from './scrapedReviews';

const Section = styled.section`
  padding: 4rem 0;
  .masonry-container {
    -webkit-column-gap: 1rem;
    -moz-column-gap: 1rem;
    column-gap: 1rem;

    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  .section-heading {
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
    margin: 0 0 1.5rem 0;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  @media (min-width: 37.5rem) {
    .masonry-container {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  @media (min-width: 58rem) {
    .masonry-container {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }
`;

const Section01 = () => {
  //

  const parsedReviews = scrapedReviews.reduce((acc, review) => {
    if (review.review_rating === '5') {
      acc = [
        ...acc,
        {
          reviewerImageUrl: review.autor_image,
          reviewerName: review.autor_name,
          localDate: new Date(review.review_timestamp * 1000).toLocaleDateString(),
          ratingValue: parseFloat(review.review_rating),
          reviewBody: review.review_text,
          reviewLink: review.review_link,
          response: review.owner_answer,
          ts: review.review_timestamp,
        },
      ];
    }
    return acc;
  }, []);

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  parsedReviews.sort(dynamicSort('-ts'));

  return (
    <Section>
      <Container>
        <h1 className="roboto-text-xl section-heading">Reviews</h1>
        <p className="roboto-text-base roboto-text-base--subtitle section-subtitle">
          What our happy customers are saying about us
        </p>
        <div className="masonry-container">
          {parsedReviews.map((review) => {
            return <ReviewCardFull key={review.reviewerName} data={review} />;
          })}
        </div>
      </Container>
    </Section>
  );
};

export default Section01;

Section01.propTypes = {
  //
};

Section01.defaultProps = {
  //
};
