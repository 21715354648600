/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { titleCase } from '../../utils';
import { StarRating } from '../../components/StarRating';

const Root = styled.div`
  width: 20rem;
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid ${({ theme }) => theme.outlinePrimaryColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.defaultSurfaceE02};
  overflow: hidden;
  display: inline-block;
  margin-bottom: 1rem;

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .date-and-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.onLightColorMediumEmphasis};
  }

  .profile-image-container {
    img {
      width: 3rem;
      height: 3rem;
      pointer-events: none;
      margin-right: 1rem;
    }
  }

  .review-body {
    line-height: 1.5;
    color: ${({ theme }) => theme.onLightColorHighEmphasis};
  }

  .response {
    margin: 1rem 0;
    /* color: ${({ theme }) => theme.onLightColorLowEmphasis}; */
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const ReviewCardFull = ({ data }) => {
  //

  const defaultSrc = (e) => {
    e.target.src =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAxCAYAAABznEEcAAAACXBIWXMAAAsSAAALEgHS3X78AAAD7ElEQVRogd1aS2gTURQ9SVNrtWUEXSnYLoOzMCq6EqwUum32UjJCd27qTl1Vxc8yLupK6FjBnZhsC9YIXSnWdjEQd62iq4IZRNs0bSN3vBnzm8yb915/HhgSkvc7c++7v/di1WoVOuFa5iAAelIAjtUNXQKwSJ+G7SzqnFOZBC86DWCIH0Ow6zsABQA5VVLSJFzLpIVPALiisgDGEoCsYTu2TOfIJFzLpLedBXBWZsIQrACYjEpGmIRrmaTfNPio/rW3gFRtQlTNhEi4lpli/RXVdx1wmUioVOICBCwAn3aZAHi+adcys2ENO0qCCUzrXp0Enhu2YwV1C5TEPiJAyLiWGahWbSXBFuit6syJ5EX/+9aXIqq/f6oOeb3dHmkhwVZoWXYPxE+cQk/6Bg5dbjVim8UPWH/52COkgHPNVqudOtmyBLpOJ9F3/1VbAmDJ9N2j/9MqJHLNPzSQYDWS8gMkgaO3bcR6+0Pb9o4/QPf5YZlpCAOuZU42zN3UQMrt1xYmQqCGw9duyU5FmGC19+CTYGs0IDMiSaF+Ewv1OX5SRRoGx21/x6r7I9AOhyEqgRpoDymgkQSH09LRKElCqp8aCYO1x5eEkrmQhQa/kdZGYrP4Xqrftpq/ACdhPgmlxIacWHUt+lutLLxRmRasUqk4h9nKKL9+GmmI8uwLbK9+0zF1Ks5JvTqJ2RlszOeF2m59/YxybkrHtITBOFcltGDt2R2Uc50lUlmYw69HGR2b2ieR0DVSDeu5KWzM57z4qKspiq3M51SDv3bQT4JAur6uT11CEZqeHgT8FyQSnAApoxYEUihBMVHXQLIhqiU/srVSxPbqd8/JkYPUtD8KSiRo4YdGxtB9YdiLSjuBCP0LFEd9YpWPc9iYnVEhVIqVMmcoLv8RdfFBKagsyOuT76DPiLjq5diuZS6L5hJkOikB2imQJ6c8XBSG7cRqG7slb90LAoSekTH0jj8UbU7lTt86FcJax470q6aUwiA1FUy0vJfvkTBsJ8cV6UCQxYmSQ6sikbwUjQRDukiwR6DS5nIziSxXog8K/JfukzBsp8REDgLyhu34+7ghADRsZzKodEOhs4QNl0aHhMmtr3QgoBarpZi8g7hp2E6DxrQEgCymu/uUQL6ZAIKiWFIr2v27sixxLAUV+MJOigqajnhVQQSG2Pi0oGM+YdjO0D6QSEcCEEmK+KzsifaliSEfRgARz7HTKgcwEeHyobyQ3xJOTzm+GtwFqVBkmhIlANm7HVxFJwuWidw5GHm+3xEaUTdD6ZYNn9ZYXJCWsWJLrKJ000Y6TdZ234kJUTWRLFrtezNoofTQ6WchbMMKAcAfyAB9UcnAy6oAAAAASUVORK5CYII=';
  };

  return (
    <Root>
      <div className="header">
        <div className="profile-image-container">
          <img
            height="48"
            width="48"
            src={data.reviewerImageUrl}
            alt={data.reviewerName}
            onError={(e) => defaultSrc(e)}
          />
        </div>
        <div>
          <div className="roboto-text-base roboto-text-base--body">{titleCase(data.reviewerName)}</div>
        </div>
      </div>
      <div className="date-and-rating">
        <div className="roboto-text-sm roboto-text-sm--body">{data.localDate}</div>
        <StarRating rating={data.ratingValue} />
      </div>
      <div>
        <p className="roboto-text-base roboto-text-base--body review-body">{data.reviewBody}</p>
        {/* {data.response && <p className="roboto-text-base response">{data.response}</p>} */}
      </div>
    </Root>
  );
};

ReviewCardFull.propTypes = {
  data: PropTypes.object.isRequired,
};
